<template>
  <div id="app">
    <CoreHeader />
    <UtilityMobileLandscape />
    <!-- <AppHeader /> -->
    <div class="wrapper" :class="wrapperClass" :style="[headerCompensation]">
      <LoaderScreen
        v-if="false"
        :class="[getIsContentLoaded ? 'unloading' : '']"
      />
      <router-view />
    </div>
    <CoreFooter />
    <ModalWindow />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

import LoaderScreen from '@/components/Loader/LoaderScreen';
import CoreHeader from '@/components/Core/CoreHeader';
import CoreFooter from '@/components/Core/CoreFooter';
import UtilityMobileLandscape from '@/components/Utility/UtilityMobileLandscape';
import ModalWindow from '@/components/Modal/ModalWindow';

export default {
  name: 'App',
  components: {
    CoreHeader,
    CoreFooter,
    UtilityMobileLandscape,
    ModalWindow,
    LoaderScreen
  },

  computed: {
    ...mapGetters(['getHeightHeader', 'getIsOpen', 'getIsContentLoaded']),
    wrapperClass() {
      switch (this.activeSide) {
        case 'left':
          return 'wrapper--left';
        case 'right':
          return 'wrapper--right';
        default:
          return '';
      }
    },
    headerCompensation() {
      return {
        'margin-top': `${this.heightHeader}px`
      };
    }
  },
  watch: {
    isOpen(val) {
      document.body.classList[val ? 'add' : 'remove']('overflow-hidden');
    }
  },
  methods: {
    debounceMethods() {
      this.$store.commit('setIsMobile', innerWidth < 1024 ? true : false);
    },
    checkCookieBotScripts(scripts) {
      if (!scripts) return;
      const scriptsCookieBot = this.getCookieBotScripts(scripts);
      return scriptsCookieBot.length > 0;
    },
    getCookieBotScripts(scripts) {
      if (!scripts) return;
      const cookiebotscripts = [];
      scripts.forEach(script => {
        const src = script.getAttribute('src');
        if (!src) return;

        const includesCookiebot = src.indexOf('cookiebot.com');
        if (includesCookiebot === -1) return;
        cookiebotscripts.push(script);
      });

      return cookiebotscripts;
    },
    // Remove scripts with a "cookiebot.com" in the src attribute
    removeCookiebot(scripts) {
      if (!scripts) return;
      const scriptsCookieBot = this.getCookieBotScripts(scripts);

      scriptsCookieBot.forEach(script => {
        script.parentNode.removeChild(script);
      });
    }
  },

  async created() {
    // check if cookiebot scripts exist already, if yes, exit
    if (await this.checkCookieBotScripts(document.scripts)) return;
    // Create cookiebot <script> tag
    const cookiebot = document.createElement('script');
    cookiebot.id = 'Cookiebot';
    cookiebot.src = 'https://consent.cookiebot.com/uc.js';
    cookiebot.setAttribute('data-cbid', 'd727c528-c2b5-4eee-804c-4f40e3a08b60');
    cookiebot.setAttribute('data-blockingmode', 'auto');
    cookiebot.type = 'text/javascript';

    // add cookiebot <script> tag as the first child  to ensure that no cookies or trackers escape the automatic cookie blocking
    document.head.insertBefore(cookiebot, document.head.firstChild);
  },

  mounted() {
    window.addEventListener('resize', _.debounce(this.debounceMethods, 400));
  },

  async beforeDestroy() {
    await this.removeCookiebot(document.scripts);
  }
};
</script>

<style lang="postcss">
@import './styles/index.css';

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  @apply font-ginto-regular;
  @apply overflow-hidden;
}

.wrapper,
main {
  @apply min-h-screen;
  @apply relative;
}

.anchored {
  top: -138px;
  padding-top: 138px;
  position: relative;
  pointer-events: none;
}
.anchored > * {
  pointer-events: initial;
}
</style>
