<template>
  <section class="block-carousel" :class="[background]">
    <h2 v-html="block.title"></h2>
    <CarouselElement
      :title="block.title"
      :subheading="block.subHeading"
      :entries="entries"
      :cta="cta"
      :persons="block.options.persons"
      v-if="entries"
      @ready="$emit('ready')"
    />
  </section>
</template>

<script>
import { background } from '@/mixins';
import CarouselElement from '@/components/Carousel/CarouselElement';

export default {
  name: 'BlockCarousel',
  components: {
    CarouselElement
  },
  props: {
    block: [Array, Object]
  },
  mixins: [background],
  data: () => {
    return {
      entries: null
    };
  },
  computed: {
    section() {
      return this.block.carousel.section;
    },
    cta() {
      return this.block.ctaGroup.length ? this.block.ctaGroup : null;
    }
  },
  created() {
    this.$http
      .get(`api/v1/carousel/${this.section}.json`)
      .then(response => {
        this.entries = response.data.data;
      })
      .catch(error => console.error(error));
  }
};
</script>

<style lang="postcss">
.block-carousel {
  @apply py-16;
  @screen sm {
    @apply py-32;
    @apply min-h-screen;
  }
  h2 {
    @apply font-ginto-black text-brand-white text-center;
    font-size: calc(28px + (56 - 28) * (100vw - 640px) / (1920 - 640));
    @apply mb-16;
    @screen sm {
      @apply mb-32;
    }
  }
}
</style>
